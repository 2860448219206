import * as React from 'react'
import Layout from '../components/layout'
import ContainerCentered from '../components/container-centered'
import ParagraphBig from '../components/paragraph-big'
import { navigate } from 'gatsby'

const FindAnInstaller = () => {

  return(
    <Layout>
      <ContainerCentered bgColor={"bg-slate-100"} minHeight={"min-h-screen"} maxWidth={"max-w-4xl"} customStyling={"py-12 md:py-0"}>
        <h1 className='text-center text-4xl lg:text-5xl max-w-4xl'>Find a heat pump installer</h1>
        <p className='mt-4 font-normal text-xl text-slate-700 text-center max-w-2xl'>
        We’ve searched high and low for the best heat pump installers in America. Enter your zip code and email below to get a list of vetted installers near you.
        </p>
        <form 
          className='grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8'
          name="find-a-heat-pump-installer" 
            onSubmit={ e => {
            e.preventDefault()
            navigate("/find-an-installer-thank-you/")
            }}
        >
          <div>
            <label htmlFor="zip" className="block text-sm font-medium text-slate-700">
              Zip code
            </label>
            <div className='mt-1'>
              <input
                name="zip"
                id="zip"
                type="text"
                autoComplete='postal-code'
                className='text-slate-700 block sm:inline-block w-full sm:w-auto text-center sm:text-left py-2 px-2 mb-1 shadow-sm sm:text-lg border border-slate-300 rounded-md'
              />
            </div>
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-slate-700">
              Email
            </label>
            <div className='mt-1'>
              <input
                name="email"
                id="email"
                type="email"
                autoComplete='email'
                className='text-slate-700 block sm:inline-block w-full sm:w-auto text-center sm:text-left py-2 px-2 mb-1 shadow-sm sm:text-lg border border-slate-300 rounded-md'
              />
            </div>
          </div>
          <button className="col-span-full block sm:inline-block w-full py-3 px-8 bg-emerald-500 text-white font-normal rounded sm:mr-1">Submit</button>
        </form>

      </ContainerCentered>

      

    </Layout>
  )
}

export default FindAnInstaller